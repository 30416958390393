.navbar {
    width: 100%;
    height: 4em;
    top: 0;
    left: 0;
    background-color: #476368;
    border-bottom: 1.5px solid #050A0D;

}

.navbar .container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:1rem;
}

.navbar .container .left {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
}

.navbar span {
    color: #192f3d;
}

.navbar .nav-menu {
    display: flex;
}

.navbar li {
    padding: 1rem;
}

.hamburger {
    display: none;
}

.navbar .icon {
    font-size: 1.5rem;
}

ul, il {
    list-style: none;
}

a {
    text-decoration: none;
    color: #050A0D;
}

.left h2{
    color: #050A0D;
}

a:hover{
    color: #192f3d;
}

img{
    height:2em;
    width:2em;
}

@media screen and (max-width:940px) {
    .hamburger {
        display: block;
    }

    .navbar .nav-menu {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 90px;
        right: 100%;
        height: 100%;
        width: 100%;
        background: #476368;
        transition: 0.4s ease-in-out;
        z-index: 10;
    }

    .navbar .active {
        right: 0;
    }

    .navbar .nav-menu li a {
        font-size: 2rem;
    }
}
.headerSkills {
    display: flex;
    justify-content: left;
    margin-left: 1em;
    margin-bottom: 1em;
    margin-right: 3em;
    padding-bottom: 0.2em;
    padding-left: 0.1em;
    border-bottom: solid #050A0D;
    color: #192f3d;
}

h3{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #192f3d;
}

.skills{
    display: flex;
    margin-top: 1em;
    margin-left: 1.5em;
}

.comingSoon{
    display: flex;
    margin-top: 1em;
    margin-left: 1.5em;
}

.skillsBox{
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;
    margin-right: 2em;
    margin-left: 1em;
    border: solid #192f3d;
    border-radius: 1%;
    flex-wrap: wrap;
    width: 80%;
}

.images{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.images img{
    width: 4em;
    height: 4em;
    margin: 3em 4em;
}

h3 img{
    margin-right: 0.5em;
}

.module div{
    width: 10em;
    height: 5em;
    margin: 1em;
    padding: 0.2em;
    border: solid #192f3d;
    border-radius: 5%;
    background: #476368;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #192f3d;
}

.module{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.skillsContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

.currentSkillsContainer,.comingSkillsContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5em;
}
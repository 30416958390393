.wrapper{
    display: flex;
    justify-content: space-evenly;
    gap: 10em;
    align-items: center;
    color: #050A0D;
    flex-wrap: wrap;
}

h1{
    display: flex;
    justify-content: center;
    align-items: center;
}

h2{
    display: flex;
    justify-content: center;
    align-items: center;
}

.header {
    width: 25em;
}

.header span{
    margin-left: 0.2em;
    color: #050A0D;
}

.mainNavbar {
    display: flex;
}

.mainNavbar img {
    display: flex;
    border-radius: 50%;
    height: 25em;
    width: 25em;
    margin-top: 2em;
    margin-left: 2em;
    border: solid #050A0D;
}

.nav {
    display: flex;
    border-radius: 50%;
    margin: 1em;
    border: solid #050A0D;
    width: 6em;
    height: 6em;
    justify-content: center;
    align-items: center;
    font-size: 0.8em;
    background-color: #476368;
}

.skillsLink{
    margin-top: 6em;
    margin-left: 3em;
}

.projectsLink{
    margin-top: 16em;
    margin-left: -5em;
}

/*.portfolioLink{
    margin-top: 25em;
    margin-left: -13em;
}*/

.meLink{
    margin-top: 26em;/*29em*/
    margin-left: -10em;/*23em*/
}

.mainNavbar .nav:hover{
    width: 6.5em;
    height: 6.5em;
}
.verweise{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 1em;
    background-position: top;
    background-size: cover;
    height: 5em;
    border-top: 1.5px solid #050A0D;
}

.imgFooter{
    display: flex;
}

.imgFooter img{
    width: 2.5em;
    height: 2.5em;
}

.imgFooter img:hover {
   width: 3em;
    height: 3em;
}

.foot{
    color: #050A0D;
}

.foot a{
    color: #192f3d;
}
.foot .verweise .icons :hover{
    color: #050A0D;
}

.imgFooter .insta{
    width: 2em;
    height: 2em;
    margin-top: 0.25em;
}


.box{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap:1em;
    color: #EAF1F6;
}

.box .project{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30em;
    height: 15em;
    margin: 3em;
    padding: 1em;
    border: solid #192f3d;
    border-radius: 5%;
}

.box .imgSkill{
    display: flex;
    justify-content: center;
    align-items: center;
}

.imgSkill img {
    width: 2em;
    height: 2em;
    margin: 1em;
}

p{
    text-align: center;
    color: #050A0D;
}

.box1{
    background-image: url("../../images/projects/Mangabg.jpg") ;
    background-size: 35em;
    font-weight: bold;
}

.box2{
    background-image: url("../../images/projects/CO2.png") ;
    background-size: 35em;
    font-weight: bold;
}

.box3{
    background-image: url("../../images/projects/Advise.png") ;
    background-size: 35em;
    font-weight: bold;
}

.box3 p{
    color: #a4bcc2;
}

.box4{
    background-image: url("../../images/projects/Screenshot 2022-07-31 at 19.51.35.png") ;
    background-size: 35em;
    font-weight: bold;
}

.box5{
    background-image: url("../../images/projects/backgroundFirstPortfolio.png") ;
    background-size: 35em;
    font-weight: bold;
}

.box6{
    background-image: url("../../images/projects/backgroundPokemon.png") ;
    background-size: 35em;
    font-weight: bold;
}

.box7{
    background-image: url("../../images/projects/backgroundRandomColor.png") ;
    background-size: 35em;
    font-weight: bold;
}

.box8{
    background-image: url("../../images/projects/backgroundScoreKeeper.png") ;
    background-size: 35em;
    font-weight: bold;
}


.box9{
    background-image: url("../../images/projects/backgroundClickMe.png") ;
    background-size: 35em;
    font-weight: bold;
}

.box10{
    background-image: url("../../images/projects/backgroundCandyMuseum.png");
    background-size: 30em;
    font-weight: bold;
}

.skillsContainer{
    display: flex;
    flex-direction: column;
}
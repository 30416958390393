.meContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

.lebenslaufContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    gap: 5em;
    margin: 5em;
    color: #050A0D;
}

.baerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    gap: 5em;
    margin: 5em;
    color: #050A0D;
}

.baer{
    width: 20em;
    height: 25em;
    border-radius: 10%;
    margin: 5em;
 }

@media screen and (max-width:940px) {
    .baer{
        display: none;
    }
}

.lebenslauf{
    width: 20em;
    height: 25em;
    border-radius: 10%;
    margin: 5em;
}

.textLebenslauf{
    text-align: center;
    width: 30em;
    border: solid #192f3d;
    border-radius: 5%;
    padding: 2em 3em;
    background-color: #4c696f;
    margin: 5em;
}

.textBaer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 30em;
    border: solid #192f3d;
    border-radius: 5%;
    padding: 5em 3em;
    background-color: #4c696f;
    margin: 5em;
}

.Icons{
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
    margin-top: 2em;
}

.Icons img{
    width: 2em;
    height: 2em;
}
